@import "../../../styles/mixin";
@import "../../../styles/variables";

.card-user_header {
  display: none;
  @include from(l){
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.card-user_avatar {
  cursor: pointer;
  img {
    transition: transform .3s;
    border-radius: 50%;
    &:hover {
      transform: scale(.9);
      transition: transform box-shadow .3s;
      box-shadow: var(--shadow);
    }
  }
}
