@import "../../../styles/mixin";
@import "../../../styles/variables";


.profile-user-avatar-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  @include from(m) {
   flex-direction: row;
  }
  @include from(920px) {
    flex: 1;
  }
}

.profile-user_avatar {
  width: 150px;
  height: 150px;
  border-radius: 70px;
}

.profile-user_content {
  text-align: center;
  @include from(m) {
    text-align: left;
  }
  
}

.custom-file-upload {
  border-radius: 30px !important;
  height: 40px !important;
  background-color: #fff !important;
  color: #0C4393 !important;
  border-color: #0C4393 !important;
  border: 1px solid #0C4393;
  padding: 8px 15px !important;
  font-size: 14px !important;
  line-height: 1.5715 !important;
  display: inline-block !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
  text-align: center !important;
}

