@import "../../../styles/mixin";
@import "../../../styles/variables";

.main-ensayos-button {
  display: flex;
  align-items: center;
  justify-content: center;
  @include from(m) {
    justify-content: flex-end !important;
  }
}

@media screen and (min-width: 840px) {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;  
  }

  
 
  .col-result {
    flex: 1;
  }
 
  .col-05 {
    flex: 0.5;
  }

  .col-10 {
    flex: 1;
  }

  .col-15 {
    flex: 1.5;
  }
  
  .col-20 {
    flex: 2;
  }
  
  .col-25 {
    flex: 2.5;
  }
  
  .col-30 {
    flex: 3;
  }
  
  .col-35 {
    flex: 3.5;
  }

  .col-40 {
    flex: 4;
  }
  
  .col-45 {
    flex: 4.5;
  }
  
  .col-50 {
    flex: 5;
  }
  
  .col-55 {
    flex: 5.5;
  }
  
  .col-60 {
    flex: 6;
  }
  
  .col-65 {
    flex: 6.5;
  }
  
  .col-70 {
    flex: 7;
  }
  
  .col-75 {
    flex: 7.5;
  }
  
  .col-80 {
    flex: 8;
  }
  
  .col-85 {
    flex: 8.5;
  }
  
  .col-90 {
    flex: 9;
  }
  
  .col-95 {
    flex: 9.5;
  }
}







