@import "../../../styles/mixin";
@import "../../../styles/variables";

.header-page-menu-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @include from(m){
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

