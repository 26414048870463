.page-header_container {
  background-color: white;
}

.page-header_container_banner {  
  background-color: #92d7cb;
}

.page-header {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-header_banner {
  width: 100%;
  height: 20%;
  background-color: #92d7cb;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  
}


.page-header_col_one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.page-header_logo {
  width: 147px;
  cursor: pointer;
}

.container-icon-toggle {
  color: black;
  position: relative !important;
}

.container-icon-bell {
  position: relative !important;
}
