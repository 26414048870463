@import "../../../styles/mixin";
@import "../../../styles/variables";

.menu-translate {
  background-color: transparent;
}

.menu-translate-button{
  background: #ffff;
}

.menu-translate_list {
  background-color: transparent;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;

  .menu-translate_item {
    cursor: pointer;
    padding: .2rem 1rem;
    font-weight: bold;
    font-size: 14px;
    color: var(--blue-primary);
    a {
      &.active {
        color: lighten($blue-primary, .9);
      }
      &:hover {
        color: lighten($blue-primary, .9);
      }
    }
    &:not(:first-child) {
      border-left: 1px solid var(--blue-primary);
    }
  }
}


