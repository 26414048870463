
.login_container {
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.login_remenber_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.gray{
  background-color: #0c43938a !important;
  color: white !important;
  border-color: #0c43938a !important;
}

.blue{
    background-color: #7677788a !important;
    color: white !important;
    border-color: #4c4d4e8a !important;
}

.login_button_subscription{
  background-color: #ffff !important;
  color: #02023b !important;
  font-size: 19px !important;
    margin-top: 8px !important;
}
