.container-buttons-historico {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .gray{
    padding: 0px;
    background-color: #7677788a !important;
    color: white !important;
    border-color: #4c4d4e8a !important;    
    width: 150px;
    margin-top:5px;
}

.style_button{
  width: 150px;
  margin-top:5px;
}


@media screen and (min-width: 840px) {
  .container-buttons-historico {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;    
  }
}