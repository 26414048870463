@import "../../../styles/mixin";
@import "../../../styles/variables";

.price-table-container {
  width: 100%;
  
  padding: 5rem 1rem 3rem;
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
}



.price-table-grid-movil {
  background-color: transparent;
  margin: 0 auto;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .slide.selected {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .carousel .slider-wrapper {
    padding-bottom: 2.5rem !important;
  }

  .carousel .control-dots .dot {
    background-color: var(--green-bold) !important;
  }
  @include from(m) {
   display: none;
  }
}

.price-table-grid {
  display: none;
  @include from(m) {
    width: 100%;
    background-color: white;
    display: grid;
    gap: 1rem;
    justify-items: center;
    justify-content: center;
    grid-template-columns: repeat(2, 290px);
  }
  @include from(l) {
    grid-template-columns: repeat(4, minmax(200px, 280px));
  }
}

.price-table-subtitle {
  @include from(m) {
    max-width: 930px;
    margin: 0 auto;
  }
}