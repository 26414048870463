@import "../../../styles/mixin";
@import "../../../styles/variables";

.profile-user-header {
  width: 100%;
  background-color: var(--green-light);
  padding: 1rem 2rem;
  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  @include from(920px) {
    flex-direction: row;
    gap: 2rem;
  }
}

// Container the data account and date init-end
.profile-user-avatar-data {
  @include from(920px) {
      flex: 1;
   }
}