@import "../../../styles/mixin";
@import "../../../styles/variables";


.page-home_grid {
  display: grid;
  gap: 1rem;

  @include from(l) {
    grid-template-columns: repeat(12, 1fr);
    .cols-1 {
      grid-column: span 9;
    }
    .cols-2 {
      grid-column: span 3;
    }
  }
}

.page-home_charts_grid .page-home_average_list {
  display: grid;
  gap: 1rem;

  @include from(920px) {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media screen and (min-width: 920px) {
  .row-home {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
  
  }
  
  .col-home {
    flex: 1;
  }
 
  .col-05 {
    flex: 0.5;
  }

  .col-10 {
    flex: 1;
  }

  .col-15 {
    flex: 1.5;
  }
  
  .col-20 {
    flex: 2;
  }
  
  .col-25 {
    flex: 2.5;
  }
  
  .col-30 {
    flex: 3;
  }
  
  .col-35 {
    flex: 3.5;
  }

  .col-40 {
    flex: 4;
  }
  
  .col-45 {
    flex: 4.5;
  }
  
  .col-50 {
    flex: 5;
  }
  
  .col-55 {
    flex: 5.5;
  }
  
  .col-60 {
    flex: 6;
  }
  
  .col-65 {
    flex: 6.5;
  }
  
  .col-70 {
    flex: 7;
  }
  
  .col-75 {
    flex: 7.5;
  }
  
  .col-80 {
    flex: 8;
  }
  
  .col-85 {
    flex: 8.5;
  }
  
  .col-90 {
    flex: 9;
  }
  
  .col-95 {
    flex: 9.5;
  }
}


