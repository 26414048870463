@import "../../../styles/mixin";
@import "../../../styles/variables";

.profile-form-row {
  width: 100%;
}

.profile-form-row-grid {
  @include from(720px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.profile-form-buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  @include from(m) {
    justify-content: flex-end;
  }
}

.button_subscription{
  background-color: #ffff !important;
  color: #02023b !important;
}
@media screen and (min-width: 840px) {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 26%;
  }

  .row-table-profile {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
  }
}