.main-average_icon {
  width: 100%;
  background: var(--gradiente-two);
  padding: 2rem 1rem;
  display: grid;
  place-items: center;
  gap: 1rem;
  color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
