@import "../../../styles/mixin";
@import "../../../styles/variables";

.matters-grid {
  width: 100%;
  display: grid;
  gap: 1rem;

  @include from(m) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include from(l) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card-matter-item-disabled {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  color: #7677788a; 
}

.price-table-item {
  width: auto;
  margin-left: 35px;
  max-width: 300px;
  height: auto;
  background-color: white;
  box-shadow: var(--shadow);
  text-align: center;
  border-radius: 30px;
  padding-bottom: 1rem;
}

.price-table-header {
  position: relative;
}

.table-control-header-image {
  width: 100%;
  height: 140px;
}

.table-header-content-control {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.price-table-list {
  background-color: transparent;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    padding-left: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: .5rem;
      padding: .3rem 0;
      border-bottom: 1px solid var(--green-second);
    }
  }
}

.button_subscription{
  background-color: #ffff !important;
  color: #02023b !important;
}
