@import "../../../styles/mixin";
@import "../../../styles/variables";

.main-ensayos-button {
  display: flex;
  align-items: center;
  justify-content: center;
  @include from(m) {
    justify-content: flex-end;
  }
}


.main-wrong-grid {
  width: 100%;
  display: grid;
  gap: 1rem;
  @include from(l) {
    grid-template-columns: repeat(12, 1fr);
  }
  .cols-1 {
    @include from(l) {
      grid-column-start: 1;
      grid-column-end: 10;
    }
  }
  .cols-2 {
    overflow-x: auto;
    @include from(l) {
      grid-column-start: 10;
      grid-column-end: 13;
    }
  }
}

.main-ensayos-pagination-questions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-ensayos-buttons-questions {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  @include from(m) {
    justify-content: flex-end !important;
  }
}

.subrayado{
  text-decoration:underline;
  color:#0C4393;
}

.url{
  padding: 0;
  background: #fff !important;  
  color: var(--blue-second);
  text-decoration: underline;
  cursor: pointer;  
}

.unselectable {
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

@media screen and (min-width: 750px) {
  .stem{ 
    width: 745px;  
  }
}

