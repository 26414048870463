@import "../../../styles/mixin";
@import "../../../styles/variables";

.main-ensayos-button {
  display: flex;
  align-items: center;
  justify-content: center;
  @include from(m) {
    justify-content: flex-end;
  }
}

.main-ensayos-context {  
  height: 300px;	
	overflow-y: scroll;
  
}
.url{
  padding: 0;
  background: #fff !important;  
  color: var(--blue-second);
  text-decoration: underline;
  cursor: pointer;  
}


.main-ensayo-grid {
  width: 100%;
  display: grid;
  gap: 1rem;
  @include from(l) {
    grid-template-columns: repeat(12, 1fr);
  }
  .cols-1 {
    overflow-x: auto;
    @include from(l) {
      grid-column-start: 1;
      grid-column-end: 10;
      
    }
  }
  .cols-2 {
    overflow-x: auto;
    @include from(l) {
      grid-column-start: 10;
      grid-column-end: 13;
    }
  }
}

.main-ensayos-pagination-questions {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}

.main-ensayos-buttons-questions {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  @include from(m) {
    overflow-x: auto;
    justify-content: flex-end !important;
  }
}

@media screen and (min-width: 750px) {
  .stem{ 
    width: 745px;  
  }
  
}


.subrayado{
  text-decoration:underline;
  color:#0C4393;
}

@media screen and (min-width: 840px) {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 26%;
  }

  .row-table {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
  }
  .ant-message{
    z-index: -1 !important;
    top: 100px !important;    
  }
 
  .col {
    flex: 1;
  }
 
  .col-05 {
    flex: 0.5;
  }

  .col-10 {
    flex: 1;
  }

  .col-15 {
    flex: 1.5;
  }
  
  .col-20 {
    flex: 2;
  }
  
  .col-25 {
    flex: 2.5;
  }
  
  .col-30 {
    flex: 3;
  }
  
  .col-35 {
    flex: 3.5;
  }

  .col-40 {
    flex: 4;
  }
  
  .col-45 {
    flex: 4.5;
  }
  
  .col-50 {
    flex: 5;
  }
  
  .col-55 {
    flex: 5.5;
  }
  
  .col-60 {
    flex: 6;
  }
  
  .col-65 {
    flex: 6.5;
  }
  
  .col-70 {
    flex: 7;
  }
  
  .col-75 {
    flex: 7.5;
  }
  
  .col-80 {
    flex: 8;
  }
  
  .col-85 {
    flex: 8.5;
  }
  
  .col-90 {
    flex: 9;
  }
  
  .col-95 {
    flex: 9.5;
  }
  
}

.edit-tag{
  background: #fff !important;
  border: none !important;
}

.all {
  -moz-user-select: all !important;
  -webkit-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.unselectable {
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

