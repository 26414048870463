@import "../styles/mixin";

$breakPoints: (
  s: 280px,
  m: 640px,
  l: 1024px,
  xl: 1200px,
);

// Variables - sass
$max-width: 1200px;
$headerHeight: 80px;
$shadow: 0px 3px 30px rgba(0,0,0, .08);
$movilToDesktop: 945px;
$blue-primary: #0C4393;
$blue-second: #0C4393;
$black: #030E1F;

// Variables (Custom Properties) - css
:root {
  --max-width: 1200px;
  --headerHeight: 92px;
  --menuVerticalWidth: 138px;
  --submenuDesplazableWidth: 229px;
  --shadow: -5px 4px 20px rgba(0, 0, 0, 0.08);

  // Colores
  --blue-primary: #02023B;
  --blue-second: #0C4393;
  --yellow: #FAE65C;
  --green-primary: #6BB886;
  --green-second: #92D7CB;
  --green-light: #E5FAF8;
  --green-bold: #4C8170;
  --gradiente-one: linear-gradient(139.2deg, #0C4393 18.61%, #6BB886 100.99%);
  --gradiente-two: linear-gradient(141.16deg, #6BB886 3.12%, #92D7CB 95.05%);
  --gradiente-three: linear-gradient(100.92deg, #0C4393 11.95%, #92D7CB 96.29%);
  --gradiente-four: linear-gradient(180deg, #02023B 0%, #0C4393 100%);
  --gradiente-five: linear-gradient(180deg, #FFF3A4 0%, #FFC700 100%);
  --black: #030E1F;
  

  // Variables de apilamineto z-index css
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}
