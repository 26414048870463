.main-page-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-page-content {
  flex: 1;
  width: 95%;
  margin: 1rem auto;
  padding: 0 .5rem;
}
