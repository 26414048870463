@import "../../../styles/mixin";
@import "../../../styles/variables";

.main-banner {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
  border-radius: 5px;
  @include from(880px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }
}

.main-banner_image {
  @include from(880px) {
    flex: 40;
  }
}

.main-banner_content {
  flex: 60;
  @include from(m) {
    padding: 1rem;
  }
  @include from(880px) {
    flex: 60;
  }
}

.main-banner_home_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  @include from(m) {
    justify-content: flex-start;
  }
}
