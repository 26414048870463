@import "../../../styles/mixin";
@import "../../../styles/variables"; 



.payment_container {
  
  width: 100%;
  min-height: 70vh;
  display: grid;
  place-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #ffff;
}

.container-buttons-payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}

//Steps 2
.card-matter-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  p:hover {
    color: lighten($blue-second, 9)
  }
}.matters-grid {
  width: 100%;
  display: grid;
  gap: 1rem;

  @include from(m) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include from(l) {
    grid-template-columns: repeat(4, 1fr);
  }
}

// Steps 3
.grid-input {
  display: grid;
  gap: 1rem;
  &.cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.terms_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.gray{
  background-color: #7677788a !important;
  color: white !important;
  border-color: #4c4d4e8a !important;
}
