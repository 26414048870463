@import "../../../styles/mixin";
@import "../../../styles/variables";

.main-menu {
  display: none;
  background-color: transparent;
  @include from(l){
    display: block;
  }
}

.main-button{
  background: #ffff;
}

.main-menu_list {
  background-color: transparent;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;

  .main-menu_item {
    cursor: pointer;
    padding: .2rem 1rem;
    font-weight: bold;
    font-size: 14px;
    color: var(--blue-primary);
    a {
      &.active {
        color: lighten($blue-primary, .9);
      }
      &:hover {
        color: lighten($blue-primary, .9);
      }
    }
    &:not(:first-child) {
      border-left: 1px solid var(--blue-primary);
    }
  }
}


