@import "../../../styles/mixin";
@import "../../../styles/variables";

.themes-historical-table-switchs-buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  @include from(m) {
    justify-content: flex-end;
  }
}

.main-control-historical-grid {
  width: 100%;
  display: grid;
  gap: 1rem;
  @include from(l) {
    grid-template-columns: repeat(12, 1fr);
  }
  .cols-1 {
    @include from(l) {
      grid-column-start: 1;
      grid-column-end: 7;
    }
  }
  .cols-2 {
    overflow-x: auto;
    @include from(l) {
      grid-column-start: 7;
      grid-column-end: 13;
    }
  }
}

.themes-table-switchs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @include from(980px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    div {
      flex: 1;
    }
  }
}

.themes-table-switchs-grid {
  display: grid;
  gap: 1rem;
  @include from(520px) {
    grid-template-columns: repeat(1,1fr);
  }
}

.themes-table-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  flex-wrap: wrap;
  span {
    padding-right: 0 !important;
  }
  input {
    max-width: 150px;
  }
}

.card-theme-table {
  width: 100%;
  overflow-x: auto;
}


span.ant-radio + * {
  height: 25% ;
}

@media screen and (min-width: 840px) {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 26%;
  }

  .row-table {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
  }
 
  .col-historical {
    flex: 1;
  }
 
  .col-05 {
    flex: 0.5;
  }

  .col-10 {
    flex: 1;
  }

  .col-15 {
    flex: 1.5;
  }
  
  .col-20 {
    flex: 2;
  }
  
  .col-25 {
    flex: 2.5;
  }
  
  .col-30 {
    flex: 3;
  }
  
  .col-35 {
    flex: 3.5;
  }

  .col-40 {
    flex: 4;
  }
  
  .col-45 {
    flex: 4.5;
  }
  
  .col-50 {
    flex: 5;
  }
  
  .col-55 {
    flex: 5.5;
  }
  
  .col-60 {
    flex: 6;
  }
  
  .col-65 {
    flex: 6.5;
  }
  
  .col-70 {
    flex: 7;
  }
  
  .col-75 {
    flex: 7.5;
  }
  
  .col-80 {
    flex: 8;
  }
  
  .col-85 {
    flex: 8.5;
  }
  
  .col-90 {
    flex: 9;
  }
  
  .col-95 {
    flex: 9.5;
  }
}



