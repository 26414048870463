@import "../../../styles/mixin";
@import "../../../styles/variables";

.header-page-main-menu {
  background-color: transparent;
}

.header-page-main-menu_list {
  background-color: transparent;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  @include from(m){
    flex-direction: row;
  }

  .main-menu_item {
    cursor: pointer;
    padding: .2rem 1rem;
    font-weight: bold;
    font-size: 14px;
    color: var(--blue-primary);
    a {
      &.active {
        color: lighten($blue-primary, .9);
      }
      &:hover {
        color: lighten($blue-primary, .9);
      }
    }
    @include from(m){
      &:not(:first-child) {
        border-left: 1px solid var(--blue-primary);
      }
    }
  }
}
