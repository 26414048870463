@import "../../../styles/mixin";
@import "../../../styles/variables";



.page-modal{    
    background: #02023b !important;  
    color: #fff !important;
    padding: 0 !important;
}

.page-modal-color{    
  background: #fff !important;    
}

.page-modal-color-tc{    
  background: #fff !important;  
  color: var(--blue-second);
  text-decoration: underline;
  cursor: pointer;  
}

.page_modal_title{    
  background-color: #fff;
  $size: 40px;
	font-family: 'Montserrat', sans-serif !important;
  font-size: $size !important;
  line-height: $size !important;
  color: #02023b !important;
  font-weight: 700 !important;
  
}
.site-back-top-basic {
  color: rgba(64, 64, 64, 0.6);
}  
.page_title  {
  span {
    display: block;
  }
}



.main_banner_title {
  @include from(l) {
    max-width: 630px;
    overflow: hidden ;
  }
}

.main_banner_subtitle {
  display: none;
  @include from(720px) {
    display: block;
  }
}